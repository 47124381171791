import { ImageInput, ImageField, useNotify, required } from 'react-admin'
import { Box, Typography, Link } from '@mui/material'
import { useState } from 'react'

export const SingleImageInput = ({source, label, placeHolderText, children, enableEdit}: any)=>{

  const enable = (enableEdit === undefined) ||(enableEdit===true)
  return (
    <ImageInput source={source} label={label} accept="image/*"
      isRequired={true}
      multiple={false}
      sx={{
        // backgroundColor: '#CCCCCC',
      }}
      placeholder={
        <Box
          sx={{
              width: '0.5',
              borderWidth: 2,
              borderStyle: 'dashed',
              borderColor: 'grey.500',
              borderRadius: '8px',
              // backgroundColor: '#CCCCCC',
              height: 60,
          }}
          ><br/>{enable?
                placeHolderText??'Drop your file here'
                :'...'
                }
        </Box>
      }
      options={{
        multiple: false,
        disabled: !enable,
        maxSize: 2000000,
        useFsAccessApi: true,
        onDropRejected:(fr)=>{
          console.log(fr)
        }
      }}
      >
      {
        children? children : <ImageField source="src" sx={{
        '& .RaImageField-image': { maxHeight: 80, minHeight: 80, }
       }}/>
      }
    </ImageInput>

  )
}

export const MultiImageInput = ({
  source, label, placeHolderText, maxFileNum, children, enableEdit,
}: any)=>{

  const [fileCount, setFileCount] = useState<number>(0);
  const notify                    = useNotify();

  return (
    <ImageInput source={source} label={label} accept="image/*"
      isRequired={true}
      multiple={true}
      //
      onRemove={ (e:any)=>{ setFileCount(fileCount-1) } }
      placeholder={
        <Box
          sx={{
              borderWidth: 2,
              borderStyle: 'dashed',
              borderColor: 'grey.500',
              borderRadius: '8px',
              height: 60,
          }}
          ><br/>{enableEdit?
                placeHolderText??'Drop your file here'
                :'...'
                }
          <br/>
        </Box>
      }
      options={{
        disabled: !enableEdit,
        multiple: true,
        maxFiles: maxFileNum,
        maxSize: 3000000,
        useFsAccessApi: true,
        validator:(f:any)=>{
          console.log("fileCount:", fileCount)
          if(fileCount >= maxFileNum){
            console.log("> maxFileNum ", maxFileNum)
            notify('maxImages')
            return {
              code: "exceeded-max-file-num",
              message: `Exceed max file number`
            };
          }
          console.dir(f)
          if( f.size > 3000000 ){
            notify('maxFileSize3M')
            return {
              code: "exceeded-max-file-size",
              message: `Exceed max file size(3M)`
            };
          }
          return null;
        },
        onDrop:(acceptedFiles)=>{
          setFileCount(fileCount+acceptedFiles.length);
        },
        onDropRejected:(fr)=>{
          console.log(fr)
        }
      }}
      >
        <ImageField source="src" title="title" sx={{
          '& .RaImageField-image': { maxHeight: 80, minHeight: 80, }
         }}/>
    </ImageInput>
  )
}

export function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://cocolink.cc/">
        CocoLink.cc
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export const GlobalDebug = (function () {
  var savedConsole = console;
  /**
  * @param {boolean} debugOn
  * @param {boolean} suppressAll
  */
  return function (debugOn: boolean, suppressAll: boolean) {
    var suppress = suppressAll || false;
    if (debugOn === false) {
      // supress the default console functionality
      console = {} as any;
      console.log = function () {};
      console.dir = function () {};
      // supress all type of consoles
      if (suppress) {
        console.info = function () {};
        console.warn = function () {};
        console.error = function () {};
      } else {
        console.info = savedConsole.info;
        console.warn = savedConsole.warn;
        console.error = savedConsole.error;
      }
    } else {
      console = savedConsole;
    }
  };
})();


export const copyTextToClipboard = async (text:string, element: any) => {
  if ('clipboard' in navigator) {
    console.log('a')
    return await navigator.clipboard.writeText(text);
  } else {
    var clipBoardElem = document.createElement("textarea");

    // clipBoardElem.hidden = true
    element.appendChild(clipBoardElem);
    clipBoardElem.value = text
    clipBoardElem.select();
    var successfulCopy = document.execCommand('copy');
    element.removeChild(clipBoardElem)
  }
}

//
// <ImageInput source="medias" label="Product pictures" accept="image/*"
//   multiple={true}
//   onRemove={ (e:any)=>{ setFileCount(fileCount-1) } }
//   placeholder={
//     <Box
//       sx={{
//           borderWidth: 2,
//           borderStyle: 'dashed',
//           borderColor: 'grey.500',
//           borderRadius: '8px',
//           height: 60,
//       }}
//       ><br/>Drop your file here
//       <br/>
//     </Box>
//   }
//   options={{
//     multiple: true,
//     maxFiles: maxFileNum,
//     maxSize: 2000000,
//     useFsAccessApi: true,
//     validator:(f:any)=>{
//       console.log("fileCount:", fileCount)
//       if(fileCount >= maxFileNum){
//         console.log("> maxFileNum ", maxFileNum)
//         notify('maxImages')
//         return {
//           code: "exceeded-max-file-num",
//           message: `Exceed max file number`
//         };
//       }
//       return null;
//     },
//     onDrop:(acceptedFiles)=>{
//       setFileCount(fileCount+acceptedFiles.length);
//     },
//     onDropRejected:(fr)=>{
//       console.log(fr)
//     }
//   }}
//   >
//     <ImageField source="src" title="title" sx={{
//       '& .RaImageField-image': { maxHeight: 80, minHeight: 80, }
//      }}/>
// </ImageInput>
