import { fetchUtils, HttpError, RaRecord } from 'react-admin';
import { GetOneParams, GetOneResult } from 'react-admin';
import { stringify } from 'query-string';
import { API, Auth } from "aws-amplify";

const apiUrl = 'https://my.api.com/';
// const apiUrl = 'https://localhost:3002/';

const httpClient = fetchUtils.fetchJson;

export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return API.get('coco-admin', `/${resource}?${stringify(query)}`, ).then(response => {
          // Add your code here
          console.log(response);
          return {
            data: response.data,
            total: response.total
          };
        })

    },

    getOne: (resource: any, params: any): Promise<GetOneResult> => {

      return API.get('coco-admin', `/${resource}/${params.id}`).then(response => {
        // console.log(response);
        return Promise.resolve(
          {
            id: response.id,
            data: response
          }
        );
      });
    },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return API.get('coco-admin', `/${resource}?${stringify(query)}`, ).then(response => {
          // console.log(response);
          return {
            data: response.data,
            total: response.total
          };
        })

    },

    update: async (resource, params) => {

      console.log({resource})
      console.log({update_params_: params})

      //
      //  upload medias files
      //
      const newPictures = params.data.medias.filter(
          p => p.rawFile instanceof File
      );
      const formerPictures = params.data.medias.filter(
          p => !(p.rawFile instanceof File)
      );
      for(const pic of newPictures){
        console.log('.......... uploading ',pic.rawFile )
        formerPictures.push( await UploadFile(pic.rawFile) );
      }

      //  upload single file
      //
      for(const key in params.data) {
        const value = params.data[key];
        if( value?.rawFile ){
          console.log('.......... uploading ',value.rawFile )
          params.data[key] = await UploadFile(value.rawFile)
        }
      }

      try{
        const res_put = await API.put('coco-admin', `/${resource}/${params.id}`, {
          body: {
            ...params.data,
            medias: formerPictures
          }
        })
        // console.dir({res_put})

        return Promise.resolve({
          data: {...params.data, id: params.id},
        });

      }catch(e){
        console.log(e)
        return Promise.reject();
      }
    },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: async (resource, params) =>{
      // console.log(resource)
      // console.log(params)
      const new_medias = []
      for( const media of params.data.medias ){
        const path = '/?contentType='+ media.rawFile.type

        await API.get('coco-s3-upload', path, ).then(async(response) =>{
          console.log(response);

          const formData = new FormData();
          formData.append("Content-Type", media.rawFile.type);
          Object.entries(response.uploadURL.fields).forEach(([k, v]) => {
            formData.append(k, v);
          });
          formData.append("file", media.rawFile);
          new_medias.push({src:response.uploadURL.url+'/'+response.uploadURL.fields.key})

          const resFetch = await fetch(response.uploadURL.url, {
            method: "POST",
            body: formData,
          });
          // console.dir(resFetch)
        });
      }

      const res_create = await API.post('coco-admin', `/${resource}`, {
        body: {
          ...params.data,
          medias: new_medias
        }
      })
      console.dir(res_create)

      return Promise.resolve({
        data: {...params.data, id: res_create.id},
        total: 0 // response.length
      });
    },

    delete: async (resource, params) => {
      const res_del = await API.del('coco-admin', `/${resource}/${params.id}`, {})
      console.dir(res_del)

      return Promise.resolve({
        data: {...params.data, id: params.id},
      });
    },

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }));
    },

    getUserProfile: () => {

      console.log("getUserProfile ...");
      const profile = localStorage.getItem("userProfile");
      if (!profile) {
        return Promise.resolve({ data: {} });
      }
      const data = JSON.parse(profile);
      console.log("getUserProfile", data);
      return Promise.resolve({ data });
    },

    getUserAttributes: async () => {
      const user = await Auth.currentAuthenticatedUser();
      // console.log(user)
      return user.attributes;
    },

    getUserAttribute: async (key: string) => {
      const user = await Auth.currentAuthenticatedUser();
      // console.log(user)
      // console.log(key in user.attributes)
      const data = (key in user.attributes)? user.attributes[key]: '' ;
      return {data: data};
    },

    updateUserAttributes: async ( data: {[key:string]: string} ) => {

      const user = await Auth.currentAuthenticatedUser();
      console.log({data})
      await Auth.updateUserAttributes(user, data);
      return {data: data}
    },

    updateUserProfile: async ({ data }) => {
      console.log({data})

      data.fullName = data.givenName
      //
      // Convert a newly uploaded file to b64
      //
      data.avatar = await (data.avatarFile.rawFile instanceof File
        ? convertFileToBase64(data.avatarFile)
        : data.avatar);

      //
      // upload and get url
      //
      if( data.avatarFile.rawFile instanceof File ){
        data.avatarFile = await UploadFile(data.avatarFile.rawFile)
      }
      // else{
      //   throw "unhandled type of data.avatarFile.rawFile "
      // }
      console.log({data})

      //
      //  call amplify API to save
      //
      const user = await Auth.currentAuthenticatedUser();
      console.log({user})
      const host_key = 'custom:avatar_link'
      const host_value = (host_key in user.attributes)?
                          JSON.parse(user.attributes[host_key]):{}
      host_value.src = data.avatarFile.src
      host_value.groupName = data.groupName;
      host_value.groupDesc = data.groupDesc;
      host_value.collectionLoc = data.collectionLoc;

      const userAttrs = {}

      try {

        console.log({host_value})
        await Auth.updateUserAttributes(user, {
          'custom:avatar_link': JSON.stringify(host_value, null, 4),
          ...(( data.givenName && data.givenName.length > 0 )?{
            'given_name': data.givenName
          }:{})
        });

      }catch(e){
        console.log(e)
        return Promise.resolve({error: e.toString(), data: null });
      }

      //
      //  Cache to local storage
      //
      localStorage.setItem( 'userProfile', JSON.stringify(data) );
      return Promise.resolve({ data });
    }
};

const UploadFile = async (file: any)=>{

  const path = '/?contentType='+ file.type
  const response = await API.get('coco-s3-upload', path, {} );

  const formData = new FormData();
  formData.append("Content-Type", file.type);
  Object.entries(response.uploadURL.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append("file", file);
  // new_medias.push({src:response.uploadURL.url+'/'+response.uploadURL.fields.key})

  const resFetch = await fetch(response.uploadURL.url, {
    method: "POST",
    body: formData,
  });
  console.dir(resFetch)
  // });

  return {src: response.uploadURL.url+'/'+response.uploadURL.fields.key};
}

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
export const convertFileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

export const convertURLToBase64 = async (url: string) => {
  try{
    // let isSafari = navigator.userAgent.indexOf("Safari") > -1;
    //
    // console.log(isSafari)
    // if(isSafari){
    //   resolve('')
    //   // TODO ....
    //   return
    // }
    const data = await fetch(url);
    const blob = await data.blob();

    return new Promise(async(resolve) => {

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    }).catch((e)=>{
      console.log(e)
      resolve('')
    })


  }catch(e){
    console.log(e)
    resolve('')
  }
}
