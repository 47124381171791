import { UserMenu, MenuItemLink, useAuthProvider } from "react-admin";
import { useLogout } from 'react-admin';
import MenuItem from '@mui/material/MenuItem';
import { useProfile, ProfileProvider } from "../profile";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitIcon     from '@mui/icons-material/PowerSettingsNew';
import { Link, useNavigate } from 'react-router-dom';
import React, { forwardRef } from 'react'
import { Typography } from '@mui/material'
import { Auth }     from 'aws-amplify'

export const MyUserMenu = (props : any) => {
  const { profileVersion } = useProfile();
  return (
    <UserMenu key={profileVersion} {...props}>
      <MenuItemLink
        to="/my-profile"
        primaryText="My Profile"
        leftIcon={<SettingsIcon />}
      />
      {
        <MyLogoutButton/>
      }
    </UserMenu>
  );
};

const MyLogoutButton = () => {
  // const logout = useLogout();
  const authProv = useAuthProvider()
  const navigate = useNavigate()

  const handleClick = async() => {
    // logout();
    // authProv.logout({})

    await Auth.signOut()
    navigate('/')
  }
  return (
      <MenuItem  onClick={handleClick}>
          <ExitIcon />
          <Typography paddingLeft='18px'>  Logout </Typography>
      </MenuItem>
  );
}
